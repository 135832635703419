const gallery = [
    {src: 'https://live.staticflickr.com/65535/52861634524_cf430465ed_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466551_51a5aa4a7e_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861634024_6399624b6d_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861855410_04daf8b53a_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861903508_50edf48ef4_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52860883347_f58254b5d6_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52860883637_20dd201e04_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466711_0212ff7c84_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52860883197_1c6cf25181_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861903628_697a762cb0_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861633749_c3bc653523_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466521_2a0844bd13_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861903348_a7af2a3138_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466346_850891f436_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861855500_791bfce186_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52860883732_399083197f_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861855425_68abde5b55_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861856005_580c417096_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466321_a03a6de6be_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861634429_c40abe3e33_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861903118_fd3e0de3a9_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52860883152_f56a831abe_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466691_d1de205546_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861633919_6cf58f6bd8_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861856180_230b067596_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861856095_662e90efef_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466476_9edf7b87b5_o.jpg'},
    // {src: 'https://live.staticflickr.com/65535/52860883757_8b1b527b49_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861634094_7b6e70a036_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861466376_a122e3dccc_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861855660_7818bf62bb_o.jpg'},
    {src: 'https://live.staticflickr.com/65535/52861902978_c3ccf90a52_o.jpg'},
];

export default gallery;